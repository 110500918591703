import axios from "axios";
import { ExchangeData } from "../global";

class axiosHelper {
  constructor() {
    this.setHeaders();
  }

  setHeaders() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  getContactFile(name: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/businessCard`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContact(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addCounter(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/counter/contact/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  addCountactCounter(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/counter/addContact/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  exchangeCounter(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/counter/exchange/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  exchangeInfo(data: ExchangeData, uuid: string) {
    return axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/exchange/${uuid}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default new axiosHelper();
