import React, { useEffect, useRef, useState } from "react";

import styles from "./PopUpExchange.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useTranslation } from "react-i18next";
import axiosHelper from "../../helpers/axiosHelper";
import { ExchangeData } from "../../global";

interface PopUpExampleProps {
  popup: boolean;
  close: (e: boolean) => void;
  success: (e: boolean) => void;
  mode: string;
  name: string;
  uuid: string;
}

const PopUpExchange: React.FC<PopUpExampleProps> = (props) => {
  const [popup, setPopup] = useState(false);

  const { t } = useTranslation();

  const [privacy, setPrivacy] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");

  const [privacyError, setPrivacyError] = useState(false);

  const exchangeInfos = async () => {
    const data: ExchangeData = {
      name: name,
      surname: surname,
      email: email,
      phone: phone,
      company_name: companyName,
    };
    if (privacy) {
      setPrivacyError(false);
      await axiosHelper
        .exchangeInfo(data, props.uuid)
        .then((response) => {
          setPrivacy(false);
          setName("");
          setSurname("");
          setEmail("");
          setCompanyName("");
          setPhone("");
          props.close(true);
        })
        .catch((error) => console.log(error));
      await axiosHelper.exchangeCounter(props.uuid);
    } else {
      setPrivacyError(true);
    }
  };

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-column justify-content-center `}
        contentClassName={` ${styles.rounded}`}
      >
        <div
          className={`d-flex flex-column justify-content-center align-items-center col-12  full bg-light-gray ${styles.rounded}`}
        >
          <Modal.Body
            className={`d-flex flex-column align-items-center  justify-content-start col-12 
            bg-light
            `}
            style={{
              //   height: "70vh",
              //   width: "100vw",
              borderRadius: "20px",

              // border: `2px solid red`,
            }}
          >
            <span
              className={`${styles.closeBtn} `}
              onClick={() => props.close(false)}
            >
              <FontAwesomeIcon
                icon={faXmark as IconProp}
                style={{ fontSize: "1.5rem" }}
              />
            </span>
            <div className="d-flex flex-column align-items-start justify-content-start col-12  mb-2 ">
              <div className="d-flex flex-column align-items-center justify-content-end col-12 p-2">
                <span className="col-9 light-title fw-bold text-center mt-2">
                  Exchange informations
                </span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-12  mt-5">
                {/* <span className="sub-title">Full name</span> */}
                <input
                  type="text"
                  className="col-10 mt-2"
                  placeholder="Name *"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-12  mt-4">
                {/* <span className="sub-title">Full name</span> */}
                <input
                  type="text"
                  className="col-10 mt-2"
                  placeholder="Surname *"
                  onChange={(e) => setSurname(e.target.value)}
                  value={surname}
                  required
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-12  mt-4">
                {/* <span className="sub-title">Email</span> */}
                <input
                  type="email"
                  className="col-10 mt-2"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-12  mt-4">
                {/* <span className="sub-title">Company name</span> */}
                <input
                  type="text"
                  className="col-10 mt-2"
                  placeholder="Company name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column align-items-center justify-content-start col-12  mt-4">
                {/* <span className="sub-title">Mobile number</span> */}
                <input
                  type="text"
                  className="col-10 mt-2"
                  placeholder="Mobile number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-5 ">
                <div className="col-10 d-flex flex-row align-items-start justify-content-start">
                  <label className={`${styles.checkboxContainer}  col-2`}>
                    <input
                      type="checkbox"
                      // checked={privacy}
                      onClick={() => setPrivacy(!privacy)}
                    />
                    <span className={`${styles.checkmark} `}></span>
                  </label>
                  <span className="col-10 " style={{ fontSize: "1rem" }}>
                    I confirm that i have read the{" "}
                    <a
                      href="https://dlctech.it/Privacy-Policy"
                      style={{ color: "#b2fc02" }}
                    >
                      privacy policy
                    </a>{" "}
                    and agree to the processing of my personal data by the user
                    or his company for the stated purposes. I can revoke this
                    consent at any time.
                  </span>
                </div>
              </div>
              {privacyError && (
                <div className="col-12 d-flex flex-row align-items-center justify-content-center">
                  <div
                    className="d-flex flex-row align-items-center justify-content-center col-9  text-center  "
                    style={{ fontSize: "1.2rem", color: "red" }}
                  >
                    Devi accettare la privacy policy per mandare le tue
                    informazioni a {props.name}
                  </div>
                </div>
              )}
              <div className="col-12 d-flex flex-row align-items-center justify-content-center mt-3">
                <div className=" col-6 d-flex flex-row align-items-center justify-content-center     ">
                  <button
                    type="submit"
                    className={`submit cursor-pointer d-flex flex-row align-items-center justify-content-center   ${styles.sendButton}`}
                    onClick={() => exchangeInfos()}
                  >
                    {t(`SEND_BUTTON`)}
                    {/* Send */}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpExchange;
